import { Theme } from "@mui/material";

export enum SnackbarVariant {
  default = "default",
  error = "error",
  success = "success",
  warning = "warning",
  info = "info",
}

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const lightTheme = (theme: Theme) => theme.palette.mode === "light";

export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;
export const TARGET_AND_TYPE_TULLI = [
  {
    value: "customs",
    name: "Customs",
  },
];
export const TARGET_GROUP: Array<any> = [
  { value: "Aviation", name: "Aviation" },
  { value: "Customs", name: "Customs" },
  { value: "Power plant", name: "Power plant" },
  { value: "Cruise", name: "Cruise" },
  { value: "Event", name: "Event" },
];

export const PRACTISE_CATEGORY: Array<any> = [
  { value: "all", name: "All" },
  { value: "Cabin", name: "Cabin" },
  { value: "Hold", name: "Hold" },
  { value: "Supplies", name: "Supplies" },
  { value: "Cargo_mail", name: "Cargo/mail" },
  { value: "Company", name: "Company" },
  { value: "Customize", name: "Customize" },
];

export enum RoleValue {
  xraytrainer = "xraytrainer",
  combine = "combine",
}

export const ADMIN_ROLE = "admin";
export const MANAGER_ROLE = "manager";

export const ROLE_LIST: Array<any> = [
  { value: "xraytrainer", name: "Xraytrainer" },
  { value: "combine", name: "Combine" },
];

export const FLOW_VALUE = {
  value: "flow",
  name: "Flow",
};

export const INDICATE_VALUE = {
  value: "indicate",
  name: "Indicate",
};

export const BOTH_VALUE = {
  value: "Both",
  name: "Both",
};

export const ALL_VALUE = {
  value: "all",
  name: "All",
};

export const IMAGE_TYPE_A_C = "A-C";
export const IMAGE_TYPE_D_C = "D-C";

export const PRACTISE_FLOW = [FLOW_VALUE, INDICATE_VALUE, BOTH_VALUE];

export const PRACTISE_FLOW_TEST = [FLOW_VALUE, INDICATE_VALUE];

export const TIME_ALLOCATED = [
  {
    value: 15,
    name: "15 seconds",
  },
  {
    value: 30,
    name: "30 seconds",
  },
  {
    value: 45,
    name: "45 seconds",
  },
  {
    value: 60,
    name: "60 seconds",
  },
  {
    value: 75,
    name: "75 seconds",
  },
  {
    value: 90,
    name: "90 seconds",
  },
  {
    value: 105,
    name: "105 seconds",
  },
  {
    value: 120,
    name: "120 seconds",
  },
];

// export const KEYBOARD_LIST = [
//   {
//     name: "Generic keyboard",
//     value: "generic_keyboard",
//   },
//   {
//     name: "Smiths keyboard",
//     value: "smiths_keyboard",
//   },
//   {
//     name: "Rap5xx keyboard",
//     value: "rap5xx_keyboard",
//   },
//   {
//     name: "Rap6xx keyboard",
//     value: "rap6xx_keyboard",
//   },
//   {
//     name: "Nuctech keyboard",
//     value: "nuctech_keyboard",
//   },
// ];

export const TARGET_AND_TYPE = [
  {
    value: "admin_facilities",
    name: "Admin facilities",
  },
  {
    value: "aviation",
    name: "Aviation",
  },
  {
    value: "customs",
    name: "Customs",
  },
  {
    value: "cruise",
    name: "Cruise",
  },
  {
    value: "event",
    name: "Event",
  },
  {
    value: "hospitality",
    name: "Hospitality",
  },
  {
    value: "mall",
    name: "Mall",
  },
  {
    value: "military",
    name: "Military",
  },
  {
    value: "power_plant",
    name: "Power plant",
  },
  {
    value: "school",
    name: "School",
  },
  {
    value: "prison",
    name: "Prison",
  },
];

export const ROLE_COMPANY_LIST: Array<any> = [
  { value: "manager", name: "Manager" },
  { value: "student", name: "Student" },
];

export const ROLE_COMPANY_STU_LIST: Array<any> = [
  { value: "student", name: "Student" },
];

export const DATE_SHOW_FORMAT = "DD-MM-YYYY HH:mm:ss";
export const DATE_SHOW_FORMAT_2 = "DD-MM-YYYY";

export enum KEYBOARD_KEY {
  SMITHS = "smiths",
  GENERIC = "generic",
  RAP9XX = "rap9xx",
  RAP6XX = "rap6xx",
  RAP5XX = "rap5xx",
  NUCTECH = "nuctech",
}

export const KEYBOARD_LIST = [
  { name: "Smiths keyboard", value: KEYBOARD_KEY.SMITHS },
  { name: "Generic keyboard", value: KEYBOARD_KEY.GENERIC },
  { name: "Rap9xx keyboard", value: KEYBOARD_KEY.RAP9XX },
  { name: "Rap6xx keyboard", value: KEYBOARD_KEY.RAP6XX },
  { name: "Rap5xx keyboard", value: KEYBOARD_KEY.RAP5XX },
  { name: "Nuctech keyboard", value: KEYBOARD_KEY.NUCTECH },
];
export const IMAGE_DOWNLOAD_PROCESSING = {
  start: "START",
  next_one: "NEXT_ONE",
};

export enum LANGUAGE_KEY {
  ENGLISH = "en",
  FINNISH = "fi",
}

export const LANGUAGE_LIST = [
  { name: "English", value: LANGUAGE_KEY.ENGLISH },
  { name: "Finnish", value: LANGUAGE_KEY.FINNISH },
];

export enum FEEDBACK_KEY {
  INDICATION = "indication",
  INDICATION_FLOW = "indication_flow",
  IMAGE_DIF = "image_dif",
  IMAGE_EASY = "image_easy",
  OTHER = "other",
}

export const FEEDBACK_LIST = [
  { name: "msg_25", value: FEEDBACK_KEY.INDICATION },
  { name: "msg_26", value: FEEDBACK_KEY.INDICATION_FLOW },
  { name: "msg_27", value: FEEDBACK_KEY.IMAGE_DIF },
  { name: "msg_28", value: FEEDBACK_KEY.IMAGE_EASY },
  { name: "msg_29", value: FEEDBACK_KEY.OTHER },
];

export enum FEEDBACK_STATUS_KEY {
  NEW = 1,
  PROGRESSING = 2,
  RESOLVED = 3,
}

export const FEEDBACK_TYPE_LIST = [
  { name: "Feedback", value: "feedback" },
  { name: "System log", value: "system_log" },
];

export const PRACTISE_STATUS_LIST = [
  { name: "new", value: "NEW" },
  { name: "in_progress", value: "IN_PROGRESS" },
  { name: "completed", value: "COMPLETED" },
];

export const FEEDBACK_STATUS_LIST = [
  { name: "New", value: FEEDBACK_STATUS_KEY.NEW },
  { name: "Progressing", value: FEEDBACK_STATUS_KEY.PROGRESSING },
  { name: "Resolved", value: FEEDBACK_STATUS_KEY.RESOLVED },
];

export const COUNTRIES_LIST = [
  {
    name: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    countryCode: "246",
    region: "Europe",
    subRegion: "Northern Europe",
    // urlPk: "https://euro.x-raytrainer.com/",
    urlPk: process.env.REACT_APP_API_TEST_URL_EU,
    urlFe: process.env.REACT_APP_API_TEST_URL_EU,
  },
  {
    name: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    countryCode: "578",
    region: "Europe",
    subRegion: "Northern Europe",
    urlPk: process.env.REACT_APP_API_TEST_URL_EU,
    urlFe: process.env.REACT_APP_API_TEST_URL_EU,
  },
  {
    name: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    countryCode: "752",
    region: "Europe",
    subRegion: "Northern Europe",
    urlPk: process.env.REACT_APP_API_TEST_URL_EU,
    urlFe: process.env.REACT_APP_API_TEST_URL_EU,
  },
  {
    name: "India",
    alpha2: "IN",
    alpha3: "IND",
    countryCode: "356",
    region: "Asia",
    subRegion: "Southern Asia",
    urlPk: process.env.REACT_APP_API_TEST_URL_ASIA,
    urlFe: process.env.REACT_APP_API_TEST_URL_ASIA,
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    countryCode: "586",
    region: "Asia",
    subRegion: "Southern Asia",
    urlPk: process.env.REACT_APP_API_TEST_URL_ASIA,
    urlFe: process.env.REACT_APP_API_TEST_URL_ASIA,
  },
];
