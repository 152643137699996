import { TabContext, TabPanel } from "@mui/lab";
import { Badge, Box, IconButton, styled, useTheme } from "@mui/material";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { H6, Paragraph, Tiny } from "../../../components/Typography";
import FlexBox from "../../../components/flexbox/FlexBox";
import NotificationsIcon from "../../../icons/NotificationsIcon";
import { searchMessageList } from "../../../services/messagesService";
import PopoverLayout from "./PopoverLayout";
import * as storage from "../../../utils/storage";
import useSettings from "../../../hooks/useSettings";
// styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.action.hover },
}));

const NotificationsPopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [totalNotRead, setTotalNotRead] = useState(0);
  const { t } = useTranslation();
  const { settings } = useSettings();

  const navigate = useNavigate();
  const showNoRead = (datas: any): number => {
    return datas?.length > 0 ? datas.filter((da: any) => !da.isRead).length : 0;
  };

  const getNoti = () => {
    const jwt = storage.get(storage.jwtName);
    // console.log(settings.isTakeExam);
    if (!jwt || settings.isTakeExam) return;
    searchMessageList(1).then((dataR) => {
      if (dataR.datas) {
        setDataList(dataR.datas);
        setTotalNotRead(showNoRead(dataR.datas));
      }
    });
  };

  useEffect(() => {
    setInterval(getNoti, 30000);
  }, []);

  useEffect(() => {
    getNoti();
  }, [open]);

  return (
    <Fragment>
      <StyledIconButton ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge color="error" badgeContent={totalNotRead}>
          <NotificationsIcon color={totalNotRead ? "success" : "disabled"} />
        </Badge>
      </StyledIconButton>

      <PopoverLayout
        title={t("notifications")}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        hiddenViewButton={true}
      >
        <TabContext value={"1"}>
          {dataList.length === 0 ? (
            <Paragraph fontWeight="500" textAlign="center" p={2}>
              {t("no_notifications")}
            </Paragraph>
          ) : (
            <>
              <TabPanel value="1">
                {dataList.map((msg) => (
                  <ListItem
                    msg={msg}
                    key={msg.id}
                    onMove={() => {
                      navigate("/company/download");
                      setOpen(false);
                    }}
                  />
                ))}
              </TabPanel>
              {/* <Box p={2}>
                <ButtonBase
                  disableRipple
                  sx={{
                    margin: "auto",
                    display: "block",
                    color: "primary.main",
                  }}
                >
                  show more
                </ButtonBase>
              </Box> */}
            </>
          )}
        </TabContext>
      </PopoverLayout>
    </Fragment>
  );
};

// ListItem component props
type ListItemProps = {
  msg: {
    id: number;
    isRead: boolean;
    image: string;
    name: string;
    mesType: string;
    message: string;
  };
  onMove: () => void;
};
function ListItem({ msg, onMove }: ListItemProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const colorbg =
    theme.palette.mode === "light" ? "secondary.light" : "divider";

  return (
    <FlexBox
      p={2}
      alignItems="center"
      sx={{
        borderBottom: 1,
        cursor: "pointer",
        borderColor: "divider",
        backgroundColor: !msg.isRead ? colorbg : "transparent",
        "&:hover": { backgroundColor: colorbg },
      }}
      onClick={onMove}
    >
      <FlexBox alignItems="center">
        <Box
          sx={{
            width: 8,
            height: 8,
            marginRight: 2,
            borderRadius: "50%",
            backgroundColor: !msg.isRead ? "primary.main" : "text.disabled",
          }}
        />
      </FlexBox>

      <Box ml={2}>
        <H6>{t(msg?.mesType)}</H6>
        <Tiny display="block" fontWeight={500} color="text.disabled">
          {msg.message}
        </Tiny>
      </Box>
    </FlexBox>
  );
}

export default NotificationsPopover;
