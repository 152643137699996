/* istanbul ignore file  */
import axios, { AxiosInstance, Method } from "axios";
import delve from "dlv";
import * as storage from "./storage";
import { COUNTRIES_LIST } from "./constants";

export const getBaseUrl = () => {
  if (process.env.MODE_IMAGE === "local") return process.env.REACT_APP_API;
  const regoin = storage.get(storage.regionKey);
  const countryF = COUNTRIES_LIST.filter(
    (country) => country.region === regoin
  );
  if (!countryF.length) return process.env.REACT_APP_API_TEST_URL_EU;
  return countryF[0].urlPk;
};

const instance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  headers: {},
  withCredentials: true,
  timeout: 1000 * 100,
});

export interface ResponseMeta {
  pages: number;
  page: number;
  page_size: number;
  total: number;
}

export function fetch<D, R>(
  url: string,
  options: { method: Method; data?: D },
  header: any = {}
): Promise<R> {
  const { method, data } = options;
  let body = data as any;
  let headers = {
    // "x-requested-with": "XMLHttpRequest",
    withCredentials: true,
    ...header,
  } as any;

  if (["post", "put"].indexOf(method) !== -1) {
    if (typeof body === "string") {
      headers["Content-Type"] = "application/json";
      headers.Accept = "application/json, text/plain, */*";
    }
  }

  return instance(url, {
    method,
    baseURL: getBaseUrl(),
    data: body,
    headers,
    responseType: url === "/api/images/getImageData" ? "blob" : "json",
  })
    .then((response) => {
      const data = delve(response, "data");
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export function fetchWithAuth<D, R>(
  url: string,
  options: { method: Method; data?: D },
  header: any = {}
) {
  if (isExpired()) {
    storage.remove(storage.jwtName);
    storage.remove(storage.jwtExpiryName);
    return Promise.reject("Expired session");
  }
  const jwt = storage.get(storage.jwtName);
  let headers = {
    ...header,
  } as any;
  if (jwt) {
    headers = {
      Authorization: `Bearer ${jwt}`,
      ...headers,
    };
  }
  return fetch<D, R>(url, options, headers);
}

export function isExpired(): boolean {
  const expiry = storage.get(storage.jwtName);
  const currentTime = new Date().getTime();
  return expiry && currentTime >= expiry;
}
