import moment from "moment";
import {
  getTimeTotalPractise,
  toHHMMSS,
} from "../pages/practises/exam/utilities/common";
import {
  ADMIN_ROLE,
  DATE_SHOW_FORMAT,
  FLOW_VALUE,
  KEYBOARD_LIST,
  LANGUAGE_LIST,
  MANAGER_ROLE,
} from "./constants";
export const searchByName = (listData: any[], searchValue: string) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};

export const getRoute = (pathname: string) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const isAdmin = (roles: string[]) => {
  if (!roles) return false;
  return roles.includes(ADMIN_ROLE) ? true : false;
};

export const isManager = (roles: string[]) => {
  if (!roles) return false;
  return roles.includes(MANAGER_ROLE) ? true : false;
};

export const showDateFormat = (date: string): string => {
  if (date) return moment.utc(date).local().format(DATE_SHOW_FORMAT);
  return "";
};

export const getPercentForCases = (
  curCase: number,
  totalIndicate: number,
  totalFlow: number,
  type: string
) => {
  try {
    if (FLOW_VALUE.value === type) {
      return Math.round(curCase > 0 ? (curCase / totalFlow) * 100 : 0);
    } else {
      return Math.round(curCase > 0 ? (curCase / totalIndicate) * 100 : 0);
    }
  } catch (error) {
    return 0;
  }
};

export const getKeyboardName = (keyboardValue: string): string => {
  const keyF = KEYBOARD_LIST.filter((keyl) => keyl.value === keyboardValue);
  if (keyF.length) return keyF[0].name;
  return "";
};

export const getLanguagedName = (languageValue: string): string => {
  const keyF = LANGUAGE_LIST.filter((keyl) => keyl.value === languageValue);
  if (keyF.length) return keyF[0].name;
  return "";
};

export const isCorrectObjMaker = (
  markers: Array<any>,
  indexObj: number,
  objs: Array<any>
): boolean => {
  if (!markers.length && objs?.length > 0) return false;
  if (markers.length > 0 && objs?.length === 0) return false;
  if (markers.length === 0 && objs?.length === 0) return true;
  try {
    const mars = markers.filter(
      (mar: any) => mar?.indexObjCorrect === indexObj && mar?.isCorrect
    );
    if (mars.length) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const getDetailOrDesc = (obj: any, language: string) => {
  const keyObj = `text${language.toUpperCase()}`;
  try {
    if (keyObj in obj) return obj[keyObj];
    return "";
  } catch (error) {
    return "";
  }
};

export const getTotalTimeForResultList = (resultList: any[]) => {
  try {
    if (!resultList || !resultList.length)
      return {
        examTime: toHHMMSS(0),
        exerciseTime: toHHMMSS(0),
        totalBags: 0,
      };
    const examList = resultList.filter((re) => re.isExam);
    const exerciseList = resultList.filter((re) => !re.isExam);

    const totalExamTime = examList.reduce((currentValue, ex) => {
      return getTimeTotalPractise(ex.timerList) + currentValue;
    }, 0);

    const totalBags = examList.reduce((currentValue, ex) => {
      return ex.totalCase + currentValue;
    }, 0);

    const totalExerciseTime = exerciseList.reduce((currentValue, ex) => {
      return getTimeTotalPractise(ex.timerList) + currentValue;
    }, 0);

    return {
      examTime: toHHMMSS(totalExamTime),
      exerciseTime: toHHMMSS(totalExerciseTime),
      totalBags: totalBags,
    };
  } catch (error) {
    return {
      examTime: toHHMMSS(0),
      exerciseTime: toHHMMSS(0),
      totalBags: 0,
    };
  }
};

export const getContentUrl = () => {
  console.log(process.env.CONTENT_UI_URL);
  return process.env.CONTENT_UI_URL;
};

export const generateRandomString = (length: number = 10): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
};
